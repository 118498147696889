import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Divider, Dropdown, Layout as antLayout, Menu, message, Space, Statistic, Tooltip} from 'antd';
import Routes from './Routes';
import menus from './menus';
import avatar from './asserts/avatar.png';
import InfoModal from '../components/InfoModal';
import OrderSearchInput from './components/OrderSearchInput';
import axios from '../request/axios';
import reduxStore from '../redux/reduxStore';
import {connect} from 'react-redux';
import youyuUtils from '../utils/YouyuUtils';
import userAuth from '../auth/UserAuth';
import AsideNav from './AsideNav';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const {Footer} = antLayout;

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initializing: userAuth.user() === null,
      menus: [...menus],
      user: null,
      balance_visible: this.balanceIsVisible()
    };
    userAuth.init(props.history);
  }

  componentDidMount() {
    if (userAuth.user() === null) {
      this.loadUserInfo();
    } else {
      this.setState({user: userAuth.user()});
    }

    setTimeout(() => {
      if (window.operation_settings.message_after_logged_in && window.operation_settings.message_after_logged_in !== '') {
        this.infoModal && this.infoModal.openModal();
      }
    }, 1000)

    this.loadBalance();
    this.interval_id = setInterval(this.loadBalance, 10 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval_id);
  }

  loadUserInfo = () => {
    this.setState({initializing: true})
    axios.get('auth/user').then(res => {
      const {data} = res.data;
      userAuth.setUser(data);
      this.setState({initializing: userAuth.user() === null, user: userAuth.user()})
    });
  }

  loadBalance = () => {
    axios.get('customer-balance').then(res => {
      const {data} = res.data;
      reduxStore.dispatch({type: 'UPDATE_FROZEN_BALANCE', value: parseFloat(data.frozen_balance)});
      reduxStore.dispatch({type: 'UPDATE_BALANCE', value: parseFloat(data.balance)});
    });
  }

  switchBalanceVisible = () => {
    if(this.balanceIsVisible()) {
      localStorage.setItem('balance_visible', 'false');
      this.setState({balance_visible: false});
    } else {
      localStorage.setItem('balance_visible', 'true');
      this.setState({balance_visible: true});
    }
  }

  balanceIsVisible = () => {
    return localStorage.getItem('balance_visible') === 'true';
  }

  logout = () => {
    axios.post('auth/logout').then(res => {
      userAuth.clearAuth();
      message.success('您已安全退出系统');
      this.props.history.push('/login');
    });
  }

  render() {
    if (!userAuth.token()) {
      userAuth.redirectToLoginPage(this.props.history);
      return <></>;
    }
    if (this.state.initializing) {
      return <></>
    }
    return (
      <div className='console-page-container'>
        <header className='header'>
          <div className='header-content'>
            <div className='logo flex grow-flow items-center'>
              <img style={{width: 35, height: 35}}
                   src='https://img.yzcdn.cn/upload_files/2021/01/11/FuS7UjK06564M1CD8817mQPtu81Q.png!60x60.jpg'
                   alt='logo'/> <span className='ml-2'>{window.website_basic_info_settings.title}商家中心</span>
            </div>
            <div className='search ml-4'>
              <OrderSearchInput/>
            </div>
            <div className='grow-flow text-right' style={{flex: '1 0 100px'}}>
              <Space size={16}>
                {
                  (window.website_basic_info_settings.kf != null && window.website_basic_info_settings.kf !== '') &&
                  <a onClick={() => this.kfModal.openModal()}>联系客服</a>
                }
                <a href='/api-doc/' target='_blank'>接口文档</a>
                <Dropdown overlay={<Menu>
                  <Menu.Item>
                    <Link to='/security/login_psw'>安全设置</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <a onClick={this.logout}>退出系统</a>
                  </Menu.Item>
                </Menu>}>
                  <a>我的账号</a>
                </Dropdown>
              </Space>
            </div>
          </div>
        </header>
        <div className='main'>
          <aside className='aside scroll scroll--simple'>
            <div className='customer-profile'>
              <img src={avatar}/>
              <div className='desc'>
                <div className='name'>{this.state.user ? this.state.user.customer_name : '-'}</div>
                <div className='id'>商家编号：{this.state.user ? this.state.user.customer_id : '-'} &nbsp; {this.state.balance_visible ? <Tooltip placement="top" title='隐藏余额'><EyeInvisibleOutlined onClick={this.switchBalanceVisible} /></Tooltip> : <Tooltip placement="top" title='显示余额'><EyeOutlined onClick={this.switchBalanceVisible} /></Tooltip>}</div>
              </div>
              <div className='balance-wrapper'>
                <Statistic title='可用余额' prefix='￥' groupSeparator=''
                           value={this.state.balance_visible ? (youyuUtils.parseAmount(this.props.balance) ?? '-') : '***'}/>
                <Statistic title='冻结资金' prefix='￥' groupSeparator=''
                           value={this.state.balance_visible ? (youyuUtils.parseAmount(this.props.frozen_balance) ?? '-') : '***'}/>
              </div>
            </div>
            <AsideNav/>
          </aside>
          <div className='content'>
            <Routes/>
            <Footer style={{textAlign: 'center'}}>
              {(window.website_basic_info_settings.company && window.website_basic_info_settings.company !== '') ? window.website_basic_info_settings.company : window.website_basic_info_settings.title} 版权所有
              ALL RIGHTS RESERVED
            </Footer>
          </div>
        </div>
        <InfoModal title='提示信息' content={window.operation_settings.message_after_logged_in}
                   ref={ref => this.infoModal = ref}/>
        <InfoModal title='客服信息' content={window.website_basic_info_settings.kf}
                   ref={ref => this.kfModal = ref}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    balance: state.balance,
    frozen_balance: state.frozen_balance
  }
}

export default connect(mapStateToProps)(withRouter(Layout));
