import { Route, Switch } from 'react-router-dom';
import Orders from '../pages/order/Orders';
import React from 'react';
import Purchase from '../pages/purchase/Purchase';
import BatchPurchase from '../pages/purchase/BatchPurchase';
import Error404 from '../pages/others/404';
import SubmitOrder from '../pages/purchase/SubmitOrder';
import CustomerBalanceRecords from '../pages/finance/CustomerBalanceRecords';
import OrderComplaints from '../pages/order-complaint/OrderComplaints';
import CustomerShops from '../pages/customer-shop/CustomerShops';
import CustomerShopOrders from '../pages/customer-shop-orders/CustomerShopOrders';
import CustomerShopMappings from '../pages/customer-shop-mapping/CustomerShopMappings';
import Dashboard from '../pages/dashboard/Dashboard';
import ChargeBalance from '../pages/finance/ChargeBalance';
import Security from '../pages/security/Security';
import BatchOrders from '../pages/batch-orders/BatchOrders';
import CustomerUsers from '../pages/customer-user/CustomerUsers';
import EditCustomerUser from '../pages/customer-user/EditCustomerUser';
import CustomerShopSettings from '../pages/customer-shop-settings/CustomerShopSettings';
import LockedCards from "../pages/order/LockedCards";

const Routes = () => {
  return <Switch>
    <Route path='/dashboard' exact component={Dashboard}/>
    <Route path='/purchase/categories' exact component={Purchase}/>
    <Route path='/purchase/categories/:id' exact component={Purchase}/>
    <Route path='/purchase/search/:keyword' exact component={Purchase}/>
    <Route path='/purchase/favorites' exact component={Purchase}/>
    <Route path='/purchase/submit-order/:product_id' exact component={SubmitOrder}/>
    <Route path='/batch-purchase' exact component={BatchPurchase}/>
    <Route path='/orders' exact component={Orders}/>
    <Route path='/locked-cards' exact component={LockedCards}/>
    <Route path='/order-complaints' exact component={OrderComplaints}/>
    <Route path='/batch-orders' exact component={BatchOrders}/>
    <Route path='/customer-shops' exact component={CustomerShops}/>
    <Route path='/customer-shop-orders' exact component={CustomerShopOrders}/>
    <Route path='/customer-shop-mappings' exact component={CustomerShopMappings}/>
    <Route path='/customer-shop-settings/:tab' exact component={CustomerShopSettings}/>
    <Route path='/charge-balance' exact component={ChargeBalance}/>
    <Route path='/balance-records' exact component={CustomerBalanceRecords}/>
    <Route path='/security/:tab' exact component={Security}/>
    <Route path='/customer-users' exact component={CustomerUsers}/>
    <Route path='/customer-users/edit/:id?' component={EditCustomerUser}/>
    <Route component={Error404}/>
  </Switch>
}

export default Routes;